<script setup>
const userStore = useUserStore();

// ==================== CONSTANTS ====================

const isAuthorized = ref(false);

// ==================== MOUNT ====================

onMounted(() => {
  // this hook works only on client side
  // resolves hydration problem
  isAuthorized.value = userStore.IS_AUTHORIZED;
});
</script>

<template>
  <div class="mobile-gradient fixed bottom-0 z-[15] block w-full bg-slate-50 px-1 pb-1 pt-2 sm:hidden">
    <section class="flex justify-evenly gap-2">
      <NuxtLink
        to="/"
        exact-active-class="active"
        class="-m-1.5 flex flex-col gap-1 fill-white/50 p-1.5 text-xs font-semibold text-white transition-all hover:drop-shadow-blue dark:hover:drop-shadow-white"
      >
        <svg class="mx-auto" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.2 2.65C12.8538 2.39036 12.4327 2.25 12 2.25C11.5673 2.25 11.1462 2.39036 10.8 2.65L3.8 7.9C3.55161 8.08629 3.35 8.32786 3.21115 8.60557C3.07229 8.88328 3 9.18951 3 9.5L3 19C3 19.5304 3.21071 20.0391 3.58579 20.4142C3.96086 20.7893 4.46957 21 5 21H8.9C9.19174 21 9.47153 20.8841 9.67782 20.6778C9.88411 20.4715 10 20.1917 10 19.9L10 15C10 14.4696 10.2107 13.9609 10.5858 13.5858C10.9609 13.2107 11.4696 13 12 13C12.5304 13 13.0391 13.2107 13.4142 13.5858C13.7893 13.9609 14 14.4696 14 15L14 19.9C14 20.1917 14.1159 20.4715 14.3222 20.6778C14.5285 20.8841 14.8083 21 15.1 21H19C19.5304 21 20.0391 20.7893 20.4142 20.4142C20.7893 20.0391 21 19.5304 21 19L21 9.5C21 9.18951 20.9277 8.88328 20.7889 8.60557C20.65 8.32786 20.4484 8.08629 20.2 7.9L13.2 2.65V2.65Z"
          />
        </svg>
        HOME
      </NuxtLink>
      <NuxtLink
        to="/my-items"
        exact-active-class="active"
        class="-m-1.5 flex flex-col gap-1 fill-white/50 p-1.5 text-xs font-semibold text-white transition-all hover:drop-shadow-blue dark:hover:drop-shadow-white"
      >
        <svg class="mx-auto" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <g>
            <path
              d="M19.9788 6.43L12.0088 2L4.03879 6.43L9.10879 9.24C9.83879 8.48 10.8688 8 12.0088 8C13.1488 8 14.1788 8.48 14.9088 9.24L19.9788 6.43ZM10.0088 12C10.0088 10.9 10.9088 10 12.0088 10C13.1088 10 14.0088 10.9 14.0088 12C14.0088 13.1 13.1088 14 12.0088 14C10.9088 14 10.0088 13.1 10.0088 12ZM11.0088 21.44L3.00879 17V8.14L8.13879 10.99C8.04879 11.31 8.00879 11.65 8.00879 12C8.00879 13.86 9.27879 15.43 11.0088 15.87V21.44ZM13.0088 21.44V15.87C14.7388 15.43 16.0088 13.86 16.0088 12C16.0088 11.65 15.9688 11.31 15.8788 10.99L21.0088 8.14V17L13.0088 21.44Z"
            />
          </g>
        </svg>
        MY ITEMS
      </NuxtLink>
      <NuxtLink
        to="/create"
        exact-active-class="active"
        class="-m-1.5 flex flex-col gap-1 fill-white/50 p-1.5 text-xs font-semibold text-white transition-all hover:drop-shadow-blue dark:hover:drop-shadow-white"
      >
        <svg class="mx-auto" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <g>
            <path
              d="M11 17H13V13H17V11L13 11L13 7H11L11 11H7L7 13L11 13V17ZM5 21C4.45 21 3.979 20.8043 3.587 20.413C3.19567 20.021 3 19.55 3 19L3 5C3 4.45 3.19567 3.979 3.587 3.587C3.979 3.19567 4.45 3 5 3L19 3C19.55 3 20.021 3.19567 20.413 3.587C20.8043 3.979 21 4.45 21 5L21 19C21 19.55 20.8043 20.021 20.413 20.413C20.021 20.8043 19.55 21 19 21L5 21Z"
            />
          </g>
        </svg>
        CREATE
      </NuxtLink>
      <NuxtLink
        to="/explore"
        exact-active-class="active"
        class="-m-1.5 flex flex-col gap-1 fill-white/50 p-1.5 text-xs font-semibold text-white transition-all hover:drop-shadow-blue dark:hover:drop-shadow-white"
      >
        <svg class="mx-auto" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M2.273 5.625C3.09435 4.89854 4.15348 4.4983 5.25 4.5L18.75 4.5C19.891 4.5 20.933 4.925 21.727 5.625C21.6357 4.89994 21.2828 4.23315 20.7346 3.74982C20.1865 3.2665 19.4808 2.99987 18.75 3L5.25 3C4.51921 2.99987 3.81349 3.2665 3.26536 3.74982C2.71722 4.23315 2.36435 4.89994 2.273 5.625ZM2.273 8.625C3.09435 7.89854 4.15348 7.4983 5.25 7.5L18.75 7.5C19.891 7.5 20.933 7.925 21.727 8.625C21.6357 7.89994 21.2828 7.23315 20.7346 6.74983C20.1865 6.2665 19.4808 5.99987 18.75 6L5.25 6C4.51921 5.99987 3.81349 6.2665 3.26536 6.74983C2.71722 7.23315 2.36435 7.89994 2.273 8.625ZM5.25 9C4.45435 9 3.69129 9.31607 3.12868 9.87868C2.56607 10.4413 2.25 11.2044 2.25 12L2.25 18C2.25 18.7956 2.56607 19.5587 3.12868 20.1213C3.69129 20.6839 4.45435 21 5.25 21L18.75 21C19.5456 21 20.3087 20.6839 20.8713 20.1213C21.4339 19.5587 21.75 18.7956 21.75 18L21.75 12C21.75 11.2044 21.4339 10.4413 20.8713 9.87868C20.3087 9.31607 19.5456 9 18.75 9L15 9C14.8011 9 14.6103 9.07902 14.4697 9.21967C14.329 9.36032 14.25 9.55109 14.25 9.75C14.25 10.3467 14.0129 10.919 13.591 11.341C13.169 11.7629 12.5967 12 12 12C11.4033 12 10.831 11.7629 10.409 11.341C9.98705 10.919 9.75 10.3467 9.75 9.75C9.75 9.55109 9.67098 9.36032 9.53033 9.21967C9.38968 9.07902 9.19891 9 9 9H5.25Z"
          />
        </svg>
        EXPLORE
      </NuxtLink>
      <NuxtLink
        v-if="isAuthorized"
        to="/profile"
        exact-active-class="active"
        class="-m-1.5 flex flex-col gap-1 fill-white/50 p-1.5 text-xs font-semibold text-white transition-all hover:drop-shadow-blue dark:hover:drop-shadow-white"
      >
        <svg class="mx-auto" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <g>
            <ellipse cx="12" cy="7.5" rx="5.5" ry="5.5" transform="rotate(-90 12 7.5)" />
          </g>
          <g>
            <path
              d="M12 14C16.6944 14 20.5 16.1905 20.5 18.8926C20.5 21.5948 15.9893 20.9269 11.2949 20.9269C6.60047 20.9269 3.5 21.5948 3.5 18.8926C3.5 16.1905 7.30558 14 12 14Z"
            />
          </g>
        </svg>
        PROFILE
      </NuxtLink>
    </section>
  </div>
</template>

<style scoped lang="scss">
.mobile-gradient {
  background: linear-gradient(283.79deg, #1e2b9d 3.33%, #653cc9 39.46%, #081869 93.33%),
    linear-gradient(0deg, #c4c4c4, #c4c4c4);
}

a.active {
  @apply pointer-events-none text-[#79C3FF] fill-white;
}
</style>
